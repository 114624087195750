import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import AllWebinars from '../../components/allWebinars'
import { Button } from '../../components/global'

const W002 = () => (
  <Layout includeNotice={false}>
    <SEO title="Webinar 002: The Anatomy of an Electrical Circuit" />
    <div className="container container-last">
      <h1>
        Webinar 002:
        <br />
        The Anatomy of an Electrical Circuit
      </h1>
      <p>
        The term Anatomy is the science concerned with the bodily structure of humans. People are
        composed of organs placed in the same location within the human body. It doesn't matter
        where or when a man or woman is born. Organs are the same internally and perform the same
        functions in everyone. Hearts pump blood. Lungs fill with air and muscles create movement.
        Doctors study these body parts and how they work together then treat people when organs fail
        to function.
      </p>
      <p>
        Electrical circuits in vehicles follow a similar anatomy principle. Electrical circuits are
        composed of the similar electrical components arranged into complete circuits to perform
        predetermined functions. It really doesn't matter in what type of vehicle the circuit
        operates. An electrical circuit performs the same function and operates the same way whether
        on an automobile, a truck, a piece of heavy equipment, a boat or an airplane. In W002 we
        compare the common anatomy of electrical circuits which reveals many circuit similarities
        that appear in every vehicle circuit. This makes testing and troubleshooting vehicle
        circuits easy to perform. If technicians understand the anatomy of circuits and
        troubleshooting procedures, they can fix any circuit on any vehicle.
      </p>
      <p>
        In Webinar W002 we analyze the essential anatomy of vehicle electrical circuits and explain
        their major similarities. I will share a method I have used for years to comprehend
        manufacturer's schematic diagrams that are hard to understand. The sections and the
        component parts of an electrical circuit are reviewed in the various ways they are arranged
        to provide a variety of vehicle functions. DC electric motors crank engines, pump fuel and
        move air. Solenoids shift transmissions, inject fuel into a cylinder and initialize a
        generator (alternator). Illumination circuits create light. Once the sections and component
        parts of an electrical circuit are understood, a technician can apply electrical testing
        procedures and troubleshoot any electrical or electronic circuit on any vehicle.
      </p>
      <ul style={{ marginBottom: 0 }}>
        <li>Watch Webinar W002 Session</li>
        <li>
          Purchase a link to download the Webinar W002 recording and watch as often as you like.
          (Download link expires after 7 days)
        </li>
      </ul>
      <Button
        type="secondary"
        text="BUY NOW for $39.00"
        href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=4fb47bc40eba4c88bb7a12d000dae44c"
      />
      <hr />
      <AllWebinars />
    </div>
  </Layout>
)

export default W002
