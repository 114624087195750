import React from 'react'
import { Link } from 'gatsby'

const AllWebinars = () => {
  return (
    <>
      <p>
        <Link to="/webinars">Return to All Webinars</Link>
      </p>
    </>
  )
}

export default AllWebinars
